import React from "react";
import moment from "moment";
import { graphql } from "gatsby";
import { Parallax } from "react-parallax";
import Layout from "../components/layouts";
import { truncateText } from "../utils";
import bgImage from "../images/header-news.jpg";

const News = ({ data }) => (
  <Layout navBg>
    <div className="news-page">
      <Parallax className="page-header" bgImage={bgImage} strength={400}>
          <div style={{height: '100vh', maxHeight: '550px'}}>
              <div className="page-header-overlay" />
              <h1 className="page-header-title white">News</h1>
          </div>
      </Parallax>
      <div className="news-list">
        {data?.prismic?.allNewss?.edges.map((edge) => {
          const { node } = edge;
          const { content, hero_image, published_date, title, _meta } = node;
          const created = moment(published_date).format("Do MMMM[,] YYYY");
          const excerpt = truncateText({ input: content && content.length && content[0].text ? content[0].text : "", len: 50 });
          return (
              <div className="news-container" key={_meta.uid}>
                  <a href={`/news/${_meta.uid}`} className="no-effects">
                      <div className="news-header" style={{backgroundImage: `url(${hero_image.url})`}}></div>
                      <div className="news-inner">
                          <h1 className="news-title">{title ? title[0].text : ""}</h1>
                          <div className="news-created">{created}</div>
                          <div className="news-excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
                          <div className="news-read">Read More {">"}</div>
                      </div>
                  </a>
              </div>
          );
        })}
      </div>
      
    </div>
  </Layout>
)

export default News;

// Query for the Blog Home content in Prismic
export const query = graphql`
{
  prismic {
    allNewss(sortBy: published_date_DESC, first: 1000) {
      edges {
        node {
          content
          hero_image
          published_date
          title
          _meta {
            uid
          }
        }
      }
    }
  }
}
`